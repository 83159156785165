import React, { Component } from 'react'
import Link from '../utils/link'

import { Logo } from './icons'

class Header extends Component {

  state = {
    offCanvas: false,
    scrolled: false,
    bannerScrolled: false,
    prevScroll: 0,
    direction: 0,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  onScroll = (e) => {
    let { direction, prevDirection, scrolled, prevScroll, bannerScrolled } = this.state

    const curScroll = window.scrollY || document.scrollTop
    const bannerHeight = document?.querySelector('.home-banner')?.offsetHeight
    scrolled = curScroll > 1

    // Make sure they scroll more than 5px
    if (Math.abs(prevScroll - curScroll) <= 5) return
    direction = curScroll > prevScroll ? 2 : 1

    if (curScroll > 1) {
      scrolled = true
    } else {
      scrolled = false
    }
    if (curScroll > bannerHeight) {
      bannerScrolled = true
    } else {
      bannerScrolled = false
    }

    prevScroll = curScroll

    requestAnimationFrame(() => {
      this.setState({
        scrolled, prevScroll, direction, bannerScrolled
      })
    })
  }

  scrollDetect = () => {
    window.clearTimeout(this.isScrolling)
    this.isScrolling = setTimeout(this.onScroll, 0)
  }

  render() {

    let { offCanvas, scrolled, bannerScrolled, direction } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let headerClass = 'header'
    headerClass += ` header--${this.props.pageContext.header}`
    headerClass += scrolled ? ' header--scrolled' : ''
    headerClass += bannerScrolled ? ' header--banner-scrolled' : ''
    headerClass += offCanvas ? ' header--off-canvas' : ''
    if (direction === 2) headerClass += ' header--hidden'

    return (
      <>
        <header className={headerClass}>
          <div className='header__inner'>
            <Link to='/' title='Catch Wreck' className='header__logo' {...props}>
              <Logo />
            </Link>
            <nav className='header__nav'>
              <ul>
                <li><Link to='/screen-printing/' {...props}>Screen Printing</Link></li>
                <li><Link to='/digital-printing/' {...props}>Digital Printing</Link></li>
                <li><Link to='/faqs/' {...props}>FAQs</Link></li>
                <li><Link to='/about-us/' {...props}>About Us</Link></li>
                <li><Link to='/start-a-project/' {...props}>Start a Project</Link></li>
              </ul>
            </nav>
            <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
              <span className='lines'></span>
            </button>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <ul>
                <li><Link to='/screen-printing/' {...props}>Screen Printing</Link></li>
                <li><Link to='/digital-printing/' {...props}>Digital Printing</Link></li>
                <li><Link to='/faqs/' {...props}>FAQs</Link></li>
                <li><Link to='/about-us/' {...props}>About Us</Link></li>
                <li><Link to='/start-a-project/' {...props}>Start a Project</Link></li>
              </ul>
            </nav>
          </div>
        </div>
      </>
    )
  }
}

export default Header
