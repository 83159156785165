import React, { Component } from 'react'
import Link from '../utils/link'
import parse from 'html-react-parser'

import { Logo, FooterLogo, Instagram, Facebook } from './icons'
import { GatsbyImage } from 'gatsby-plugin-image'

class Footer extends Component {

  state = {
    accordionOpen: false,
  }

  _toggleAccordion = (accordionItem) => {
    this.setState({accordionOpen: {[accordionItem]: !this.state.accordionOpen[accordionItem]} })
  }

  render() {

    let { wp } = this.props.data
    let { accordionOpen } = this.state

    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <Link to='/' title='Catch Wreck' className='footer__logo'>
            <Logo />
          </Link>
          <div className='footer__menus'>
            <div className='footer__menu'>
              <h4>Follow Us</h4>
              <ul>
                { wp.siteOptions.acf.social.instagram && <li><Link to={wp.siteOptions.acf.social.instagram}><Instagram /></Link></li> }
                { wp.siteOptions.acf.social.facebook && <li><Link to={wp.siteOptions.acf.social.facebook}><Facebook /></Link></li> }
              </ul>
            </div>
            <div className={`footer__menu${accordionOpen[0] ? ' active' : ''}`}>
              <button onClick={() => this._toggleAccordion(0)}>{wp.siteOptions.acf.services.title}</button>
              <ul>
                { wp.siteOptions.acf.services.menu.map((el, i) => (
                  <li key={i}><Link to={el.link.url}>{parse(el.link.title)}</Link></li>
                )) }
              </ul>
            </div>
            <div className={`footer__menu${accordionOpen[1] ? ' active' : ''}`}>
              <button onClick={() => this._toggleAccordion(1)}>{wp.siteOptions.acf.resources.title}</button>
              <ul>
                { wp.siteOptions.acf.resources.menu.map((el, i) => (
                  <li key={i}><Link to={el.link.url}>{parse(el.link.title)}</Link></li>
                )) }
              </ul>
            </div>
            <div className={`footer__menu${accordionOpen[2] ? ' active' : ''}`}>
              <button onClick={() => this._toggleAccordion(2)}>{wp.siteOptions.acf.explore.title}</button>
              <ul>
                { wp.siteOptions.acf.explore.menu.map((el, i) => (
                  <li key={i}><Link to={el.link.url}>{parse(el.link.title)}</Link></li>
                )) }
              </ul>
            </div>
          </div>
          <div className='footer__contact'>
            <ul className='footer__social'>
              { wp.siteOptions.acf.social.instagram && <li><Link to={wp.siteOptions.acf.social.instagram}><Instagram /></Link></li> }
              { wp.siteOptions.acf.social.facebook && <li><Link to={wp.siteOptions.acf.social.facebook}><Facebook /></Link></li> }
            </ul>
            <h4>We use and recommend</h4>
            <ul className='footer__recommend'>
              { wp.siteOptions.acf.recommend.map((el, i) => (
                <li key={i}>
                  <Link to={el.link} title='Catch Wreck'>
                    { <GatsbyImage image={el.logo.localFile.childImageSharp.gatsbyImageData} alt={`Catch Wreck`} /> }
                  </Link>
                </li>
              )) }
            </ul>
            <h4>Contact Us</h4>
            { wp.siteOptions.acf.contact.address && <p className='address'>{parse(wp.siteOptions.acf.contact.address)}</p> }
            { wp.siteOptions.acf.contact.email?.url && <p><Link to={wp.siteOptions.acf.contact.email.url}>{wp.siteOptions.acf.contact.email.title}</Link></p> }
            { wp.siteOptions.acf.contact.copyright && <p>{parse(wp.siteOptions.acf.contact.copyright)}</p> }
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
